import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This statement is `}<strong parentName="p">{`FALSE`}</strong>{`...`}</p>
    <p>{`I have seen that statement multiple times and `}<strong parentName="p">{`never`}</strong>{` has that been the case.`}</p>
    <p>{`DuckDNS is like a phonebook, it has `}<strong parentName="p">{`one`}</strong>{` job and that is to assign a subdomain name to an IP,`}</p>
    <p>{`If `}<inlineCode parentName="p">{`my-ha-domain.duckdns.org`}</inlineCode>{` resolves to your IP then DuckDNS is working and you should check elsewhere.`}</p>
    <p>{`Verify this with `}<inlineCode parentName="p">{`nslookup`}</inlineCode>{` on Windows (`}<inlineCode parentName="p">{`dig`}</inlineCode>{` on MacOS/linux) or a webservice like `}<a parentName="p" {...{
        "href": "https://network-tools.com/nslook/"
      }}>{`https://network-tools.com/nslook/`}</a></p>
    <p>{`Example from `}<inlineCode parentName="p">{`nslookup`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`C:\\Users\\user>nslookup my-ha-domain.duckdns.org
Server:  a0d7b954-pi-hole
Address:  192.168.2.200

Non-authoritative answer:
Name:    my-ha-domain.duckdns.org
Address:  127.0.0.1
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      